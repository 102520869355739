import React from 'react'
import styled from '@emotion/styled';
import { theme } from '../../config/theme';

const StyledSection = styled.section`
  margin-bottom: ${props => props.small ? '75px' : '150px'};
  margin-top: ${props => props.small ? '-32px' : '0'};

  @media (max-width: ${theme.breakpoints.l}) {
    margin-bottom: ${props => props.small ? '55px' : '90px'};
  }
`

const Section = ({children,...props}) => (
  <StyledSection {...props}>{children}</StyledSection>
)

export default Section;