import React from 'react'
import { graphql,Link } from 'gatsby'
import get from 'lodash/get'

import heroStyles from '../components/hero/hero.module.css'
import CardDetail from '../components/card/cardDetail'
import Section from '../components/layout/section'

class BlogPostTemplate extends React.Component {
  render() {
    console.log("------------------------",this.props);
    const post =  this.props.data.contentfulFb500 ?  this.props.data.contentfulFb500: this.props.data.contentfulArtikel;
    //const post = get(this.props, isFB500 ? 'data.contentfulArtikel':'data.contentfulArtikel')
    //const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Section>
      <div className="container--narrow">
      <CardDetail isModal={false} id={post.id} data={post} />
      <Link className="back" to="/kunden-leistungen">Alle Projekte ansehen</Link>
      </div>
      </Section>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    

    contentfulArtikel(slug: { eq: $slug }) {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            leistung
            template:produkt
            highlight
            kunde {
              name
              id
            }
            image:heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            kunde {
              name
              url
              body {
                childMarkdownRemark {
                  html
                }
              }
              logo {
                fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              artikel {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                leistung
                template:produkt
                kunde {
                  name
                  id
                }
                image:heroImage {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                description {
                  childMarkdownRemark {
                    html,
                    excerpt
                  }
                }
              }
            }
          }
          allContentfulFb500 {
      edges {
        node {
          slug
          title
          updatedAt(formatString: "DD.MM.YYYY")
          fb500links {
            datum(formatString: "DD.MM.YYYY")
            title
            url
          }
          image:heroImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
      allContentfulArtikel(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            leistung
            template:produkt
            highlight
            kunde {
              name
              id
            }
            image:heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            kunde {
              name
              url
              body {
                childMarkdownRemark {
                  html
                }
              }
              logo {
                fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              artikel {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                leistung
                template:produkt
                kunde {
                  name
                  id
                }
                image:heroImage {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                description {
                  childMarkdownRemark {
                    html,
                    excerpt
                  }
                }
              }
            }
          }
        }
      }
      contentfulFb500(slug: { eq: $slug }) {
            slug
            title
            updatedAt(formatString: "DD.MM.YYYY")
            fb500links {
              datum(formatString: "DD.MM.YYYY")
              title
              url
            }
            image:heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
     
  }
`
